/**
 * @param {Window} globalScope
 * @returns {typeof CustomValidation}
 */
export function defCustomValidation({ HTMLElement, customElements }) {
  class CustomValidation extends HTMLElement {
    static register(tagName = "custom-validation") {
      this.tagName = tagName;
      customElements.define(tagName, this);
    }

    connectedCallback() {
      const inputs = this.querySelectorAll("input[data-validation-message]");
      for (const input of inputs) {
        input.addEventListener("invalid", function (e) {
          this.setCustomValidity("");
          if (e.target.validity.valid) return;
          this.setCustomValidity(this.dataset.validationMessage);
        });
        input.addEventListener("input", function () {
          this.setCustomValidity("");
        });
      }
    }
  }
  return CustomValidation;
}

if (globalThis.window?.customElements) {
  defCustomValidation(window).register();
}
