/**
 * @param {Window} globalScope
 * @returns {typeof ShopSteps}
 */
export function defShopSteps({ HTMLElement, customElements, location }) {
  class ShopSteps extends HTMLElement {
    static register(tagName = "shop-steps") {
      this.tagName = tagName;
      customElements.define(tagName, this);
    }

    goto(step, filter) {
      this.dispatchEvent(
        new CustomEvent("step-change", {
          detail: { step, filter },
          bubbles: true,
        }),
      );
    }

    async connectedCallback() {
      this.steps = this.querySelectorAll(".step");
      this.addEventListener("click", (e) => {
        // Handle click on radio or radio label
        if (e.target?.name === "sampleType") {
          this.handleSampleTypeChange(e);
        }
      });
      this.addEventListener("step-change", (e) => {
        const state = {
          step: e.detail.step,
          filter: e.detail.filter?.substring(7),
        };
        const params = new URLSearchParams(state);
        history.pushState(state, undefined, `/?${params}`);
        this.changeStep(state);
      });

      for (const btn of this.querySelectorAll("button[data-next-step]")) {
        btn.addEventListener("click", function () {
          const validate = this.dataset.checkValidity;
          if (validate && !this.form[validate][0].reportValidity()) {
            return false;
          }
          this.dispatchEvent(
            new CustomEvent("step-change", {
              detail: {
                step: this.dataset.nextStep,
                filter: this.dataset.filter,
              },
              bubbles: true,
            }),
          );
        });
      }

      const params = new URLSearchParams(location.search);
      if (params.has("step") && params.has("filter")) {
        const step = params.get("step");
        const filter = params.get("filter");
        const { totalSteps } = this.querySelector(
          `input[name='sampleType'][value='${filter}']`,
        ).dataset;
        this.#setFilerAndStepsNr(filter, totalSteps);
        this.changeStep({ step, filter });
      }
    }

    changeStep({ step, filter }) {
      console.log({ event: "change step", step, filter });
      const changeInternal = () => {
        try {
          this.querySelector("bottom-sheet").close();
        } catch (err) {}
        this.dataset.currentStep = step;
        this.dataset.filter = `step-${filter}`;
        if (step !== "step-3" && filter !== "kreativ") return;
        const li = this.querySelector(".step-2 li:has(input:checked)");
        if (!li) return;
        const { restriction } = li.dataset;
        this.querySelector(".step-3").dataset.restriction = restriction;
      };
      if (!document.startViewTransition) changeInternal();
      else document.startViewTransition(() => changeInternal());
    }

    handleSampleTypeChange(e) {
      const { totalSteps } = e.target.dataset;
      const filter = e.target.value;
      this.#setFilerAndStepsNr(filter, totalSteps);
    }

    #setFilerAndStepsNr(filter, totalSteps) {
      this.dataset.filter = `step-${filter}`;
      this.dataset.totalSteps = totalSteps;
      this.style.setProperty("--total-steps", Number.parseInt(totalSteps, 10));
      this.#renderSteps();
    }

    #renderSteps() {
      const { filter, currentStep, totalSteps } = this.dataset;
      for (const step of this.steps) {
        const isA = (token) => step.classList.contains(token);
        if (isA("step-initial")) continue;
        if (isA("step-final")) {
          const no = step.querySelector("header>small>data");
          no.value = totalSteps;
          no.textContent = totalSteps;
          continue;
        }
        const selectedType = isA(filter);
        for (const el of step.querySelectorAll("fieldset")) {
          el.disabled = !selectedType;
        }
        step.ariaHidden = !selectedType;
      }
      for (const hidden of this.querySelectorAll(
        `.step:is(.${filter}.${currentStep}) input[type=hidden][data-value]`,
      )) {
        hidden.value = hidden.dataset.value;
      }
      for (const hidden of this.querySelectorAll(
        `.step:not(.${filter}) input[type=hidden][data-value]`,
      )) {
        hidden.value = "";
      }
    }
  }
  return ShopSteps;
}

if (globalThis.window?.customElements) {
  defShopSteps(window).register();
}
