/**
 * @param {Window} globalScope
 * @returns {typeof BottomSheet}
 */
export function defBottomSheet({ HTMLElement, customElements }) {
  class BottomSheet extends HTMLElement {
    static register(tagName = "bottom-sheet") {
      this.tagName = tagName;
      customElements.define(tagName, this);
    }

    close() {
      this.classList.add("sheet--closed");
      this.removeDetails();
    }

    removeDetails() {
      for (const detail of this.querySelectorAll(".color-detail")) {
        this.removeChild(detail);
      }
    }

    open() {
      this.classList.remove("sheet--closed");
    }

    /**
     * @param {CSSStyleDeclaration} computedStyle
     * @param {Node} colorDetailEl
     * @param {string} lastStep
     */
    injectDetail({ computedStyle, colorDetailEl, lastStep }) {
      this.removeDetails();
      this.style.setProperty(
        "--surface-color",
        computedStyle.getPropertyValue("--surface-color"),
      );
      this.insertAdjacentElement("beforeend", colorDetailEl);
      const button = this.querySelector(".action-button");
      if (lastStep) {
        button.classList.add("add-to-cart");
      } else {
        button.classList.remove("add-to-cart");
      }
    }

    connectedCallback() {
      this.classList.add("sheet", "sheet--closed");
      this.addEventListener("click", (e) => {
        if (e.target.nodeName !== "BUTTON") return;
        this.close();
      });

      addEventListener("keydown", (e) => {
        if (e.key !== "Escape") return;
        this.close();
      });
    }
  }
  return BottomSheet;
}

if (globalThis.window?.customElements) {
  defBottomSheet(window).register();
}
