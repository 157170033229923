import { formatMoney } from "../lib/format.js";

function renderItemTemplate(item, id, product) {
  const imgEl = item.querySelector("img");
  const labelEl = item.querySelector("span");
  const priceEl = item.querySelector("data");
  const buttonEl = item.querySelector("button");

  buttonEl.dataset.id = id;
  imgEl.src = product.image;
  labelEl.innerHTML = product.title;
  priceEl.value = product.price;
  priceEl.innerHTML = formatMoney(product.price);

  return item;
}

/**
 * @param {Window} globalScope
 * @return {typeof OrderItems}
 */
export function defOrderItems({ HTMLElement, customElements }) {
  class OrderItems extends HTMLElement {
    #itemTemplate;
    #orderItems;

    static register(tagName = "order-items") {
      this.tagName = tagName;
      customElements.define(tagName, this);
    }

    set items(items) {
      const template = this.#itemTemplate.content;
      const fragment = document.createDocumentFragment();
      for (const [id, product] of items) {
        fragment.appendChild(
          renderItemTemplate(template.cloneNode(true), id, product),
        );
      }
      this.#orderItems.replaceChildren(fragment);
    }

    connectedCallback() {
      this.#itemTemplate = this.querySelector("template");
      this.#orderItems = this.querySelector(".items");
      this.addEventListener("click", (e) => {
        // Handle only Remove item button events
        if (!e.target.classList.contains("remove-item")) return;
        const { id } = e.target.dataset;
        this.dispatchEvent(
          new CustomEvent("cart:remove-item", {
            bubbles: true,
            detail: { id },
          }),
        );
      });
    }
  }
  return OrderItems;
}

if (globalThis.window?.customElements) {
  defOrderItems(window).register();
}
