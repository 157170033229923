export class Cart {
  #items = new Map();
  init() {
    this.#items = new Map(
      JSON.parse(localStorage.getItem("cart/items") ?? "null"),
    );
    this.#dispatchChangeEvent();
  }
  get size() {
    return this.#items.size;
  }
  get items() {
    return Array.from(this.#items);
  }
  get products() {
    return Array.from(this.#items.values());
  }
  get total() {
    return Array.from(this.#items.values())
      .map((x) => x.price)
      .reduce((a, b) => a + b, 0);
  }
  addItem(item) {
    const id = crypto.randomUUID();
    this.#items.set(id, item);
    this.#store();
  }
  removeItem(id) {
    this.#items.delete(id);
    this.#store();
  }
  empty() {
    this.#items = new Map();
    this.#store();
  }

  toJSON() {
    return JSON.stringify(this.products);
  }

  #store() {
    localStorage.setItem("cart/items", JSON.stringify(this.items));
    this.#dispatchChangeEvent();
  }

  #dispatchChangeEvent() {
    dispatchEvent(
      new CustomEvent("cart:change", {
        detail: { items: this.items, total: this.total },
      }),
    );
  }
}
