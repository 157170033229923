import { Cart } from "../lib/cart.js";

/**
 * @param {Window} globalScope
 * @returns {typeof OrderForm}
 */
export function defOrderForm({ HTMLElement, customElements }) {
  class OrderForm extends HTMLElement {
    #cartStorage = new Cart();
    #form;
    #productImages;
    #renderings;

    static register(tagName = "order-form") {
      this.tagName = tagName;
      customElements.define(tagName, this);
    }

    get cart() {
      return this.#cartStorage;
    }

    get totalCosts() {
      const { delivery } = this.#form;
      const selectedDelivery = Array.from(delivery).find(
        (el) => el.value === delivery.value,
      );
      const deliveryCost = Number.parseInt(
        selectedDelivery?.dataset?.price ?? "0",
        10,
      );
      const productsCost = this.#cartStorage.total;
      return productsCost + deliveryCost;
    }

    init() {
      this.#cartStorage.init();
      this.restoreContactInfo();
    }

    reset() {
      this.#form.reset();
    }

    restoreContactInfo() {
      const contact = JSON.parse(localStorage.getItem("contact") ?? "{}");
      this.#form.name.value = contact.name ?? this.#form.name.value;
      this.#form.email.value = contact.email ?? this.#form.email.value;
      this.#form.phone.value = contact.phone ?? this.#form.phone.value;
    }

    connectedCallback() {
      this.#form = this.querySelector("form");
      const { renderings, productImages } = JSON.parse(
        this.querySelector("script[type='application/json']").text,
      );
      this.#renderings = new Map(renderings);
      this.#productImages = new Map(productImages);

      this.#form.addEventListener("click", (e) => {
        // Handle only Add to Cart events
        if (!e.target.classList.contains("add-to-cart")) return;

        const { form } = e.target;
        const selectedProduct = form.querySelector(
          "fieldset:not([disabled]) input[data-price]:checked",
        );
        if (!selectedProduct) return;
        const data = Object.fromEntries(new FormData(form));
        this.#cartStorage.addItem({
          price: Number.parseInt(selectedProduct.dataset.price),
          name: selectedProduct.value,
          type: selectedProduct.name,
          title:
            data.sampleType === "catalog"
              ? "Zapůjčení katalogu maxit kreativ<br><em>vratná záloha</em>"
              : `${this.#renderings.get(data.rendering) ?? this.#renderings.get("design-board")}
                 <br>${data.material ?? "maxit design"}<br>${data.color}`,
          image:
            data.sampleType === "catalog"
              ? this.#productImages.get("catalog")
              : (this.#productImages.get(data.rendering) ??
                this.#productImages.get("design-board")),
        });
        form.items.value = this.#cartStorage.toJSON();
      });

      this.#form.addEventListener("reset", () => {
        this.#cartStorage.empty();
      });

      this.#form.addEventListener("submit", (e) => {
        // Store contact data into localStorage for next visit
        const data = Object.fromEntries(new FormData(e.target));
        localStorage.setItem(
          "contact",
          JSON.stringify({
            name: data.name,
            phone: data.phone,
            email: data.email,
          }),
        );
      });

      this.addEventListener("cart:remove-item", (e) => {
        this.#cartStorage.removeItem(e.detail.id);
      });
    }
  }
  return OrderForm;
}

if (globalThis.window?.customElements) {
  defOrderForm(window).register();
}
